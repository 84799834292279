import React from 'react'

const Appdetail = ({ waterObj }) => {
    return (
        <>
            <div className='container'>
                <div class="row">
                    <div className='col-md-2'>
                        <img src={waterObj.logo} alt="" className='img-fluid' />
                    </div>
                    <div className='col-md-10'>
                        <div className='app-name'>{waterObj.name}</div>
                        <div>
                            <img className='stars' src={waterObj.ratingIcon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='app-description'>
                            {waterObj.description}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Appdetail

{/* <button className='see-more-btn'>See more</button> */ }