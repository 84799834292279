import React from 'react'
import Navbar from '../Navbar/navbar'
import Footer from '../Footer/footer'
import '../MobileAppTestimonial/mobileAppTestimonial.css'
import MobileAppTestimonial from '../MobileAppTestimonial/mobileAppTestimonial'

const TestimonialPage = () => {
    return (
        <>
            <Navbar />
            <MobileAppTestimonial />
            <Footer />
        </>
    )
}

export default TestimonialPage
