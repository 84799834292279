import React from 'react'
import './header.css'

const Header = () => {
    return (
        <>
            <div className='container-fluid header-bg-container'>
                <div className='row'>
                    <div className='col-md-12 header-margin'>
                        <div className='col-md-12 header-container-center'>
                            <span className='header-title'>We</span>
                            <span className='header-title-red'>Design</span>
                            <span className='header-title'>Ideas &</span>
                            <span className='header-title-red'>Code</span>
                        </div>
                        <div className='col-md-12 header-container-center'>
                            <span className='header-subtitle'>them to life</span>
                        </div>
                        <div className='col-md-12 header-container-center header-container mt-5'>
                            <p>AppsKottage is a leading firm in mobile development arena <br /> that turns your out-of-the-box ideas into high-grossing apps.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header


{/* <div className='header-container-top'>
                <div className='header-container'>
                    <span className='header-title'>We</span>
                    <span className='header-title-red'>Design</span>
                    <span className='header-title'>Ideas &</span>
                    <span className='header-title-red'>Code</span>
                </div>
                <div className='header-container'>
                    <span className='header-subtitle'>them to life</span>
                </div>
            </div>
            <div className='header-container'>
                <p>AppsKottage is a leading firm in mobile development arena <br /> that turns your out-of-the-box ideas into high-grossing apps.</p>
            </div> */}