import React from 'react'
import LiveApplication from '../LiveApplications/liveapplication'
import Navbar from '../Navbar/navbar'
import Footer from '../Footer/footer'

const PorfolioPage = () => {
    return (
        <>
            <Navbar />
            <LiveApplication />
            <Footer />
        </>
    )
}

export default PorfolioPage
