import React from 'react'
import Navbar from '../Navbar/navbar'
import Footer from '../Footer/footer'
import ContactUs from '../ContactUs/contactus'
import '../ContactUs/contactus.css'

const ContactusPage = () => {
    return (
        <>
            <Navbar />
            <ContactUs />
            <Footer />
        </>
    )
}

export default ContactusPage
