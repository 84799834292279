import React from 'react'

const AboutUs = () => {
    return (
        <>
            <div className='main-container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='about-us'>ABOUT US</div>
                            <div className='title'>Creative Design and <br /> Development for your product</div>
                            <div className='description mt-5'>AppsKottage is a leading firm in mobile development arena that turns your <br /> out-of-the-box ideas into high-grossing apps.Our would-be app developers are <br /> all you need to turn your unbaked ideas into a fully developed, responsive and <br /> user-friendly app.</div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="solid hr-line mt-5"></hr>
        </>
    )
}

export default AboutUs