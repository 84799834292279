import React from 'react'
import Home from './home'
import { Routes, Route, Switch, HashRouter } from 'react-router-dom'
import PorfolioPage from './components/Pages/porfolioPage'
import TestimonialPage from './components/Pages/testimonialPage'
import ContactusPage from './components/Pages/contactusPage'
import PrivacypolicyPage from './components/Pages/privacypolicyPage'
import TermsconditionPage from './components/Pages/termsconditionPage'

const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />}> </Route>
        <Route path='/portfolio' element={<PorfolioPage />}> </Route>
        <Route path='/testimonial' element={<TestimonialPage />}> </Route>
        <Route path='/contactus' element={<ContactusPage />}> </Route>
        <Route path='/privacypolicy' element={<PrivacypolicyPage />}> </Route>
        <Route path='/termscondition' element={<TermsconditionPage />}> </Route>
      </Routes>
    </>
  )
}

export default App