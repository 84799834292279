import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {

    const clientSays = [
        {
            'name': 'Sebastian Weiser',
            'description': 'The result was exactly how it was intended and also changes within the project were no problems at all.'
        },
        {
            'name': 'Faisal Ibrahim',
            'description': 'Very skillful team who I would recommend and definitely will work again with them on new projects! Thank you for delivering an awesome job!'
        },
        {
            'name': 'Wyld Blue',
            'description': 'Fantastic developer, so efficient, reliable and responsive to all the requests. We will most definitely be working with him long term on this and many more projects to come.'
        }
    ];

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <span>
            <img src='./images/previous-arrow.png' alt="prevArrow" {...props} />
        </span>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <img src='./images/next-arrow.png' alt="nextArrow" {...props} />
    );

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        initialSlide: 0,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };

    return (
        <>
            <div className='testimonial-container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row client-title'>Mobile App Development</div>
                            <div className='row testimonial-title'>Testimonials</div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        {
                            clientSays.map((obj) => {
                                return (
                                    <div className='col-md-4 d-flex align-items-stretch mt-5 mb-5'>
                                        <div className='card p-4 testimonial-card-center'>
                                            <img className='testi-profile' src="./images/testi-profile.png" alt="" />
                                            <p className='testi-user mt-2'>{obj.name}</p>
                                            <p className='testi-desp mt-5'>{obj.description}</p>
                                            <img className='stars mt-5' src="./images/stars-img.png" alt="" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial


{/* <Slider {...settings} >
{
    clientSays.map((obj) => {
        return (
            <div className='col-md-4 d-flex align-items-stretch mt-5 mb-5'>
                <div className='card p-5 testimonial-card-center'>
                    <img className='testi-profile' src="./images/testi-profile.png" alt="" />
                    <p className='testi-user mt-2'>{obj.name}</p>
                    <p className='testi-desp mt-5'>{obj.description}</p>
                    <img className='stars mt-5' src="./images/stars-img.png" alt="" />
                </div>
            </div>
        )
    })
}
</Slider> */}