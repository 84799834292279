import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {

    return (
        <>
            <footer>
                <div className='container-fluid footer-bg'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12 p-5'>
                                        <div className='logo-spacing' >
                                            <img src="./images/nav-company-name.png" alt="" width="284" height="46" class="d-inline-block align-text-center img-fluid" />
                                        </div>
                                        <div className='mt-2'>
                                            <p className='footer-company-desp'>
                                                Design, Develop & Distribute high Quality mobile apps
                                            </p>
                                        </div>
                                        <div className='mt-5'>
                                            <a href='https://www.facebook.com/appskottage' target="_blank" rel="noopener noreferrer">
                                                <img src="./images/social-fb-icon.png" alt="" className='footer-social-icon' />
                                            </a>
                                            <a href="https://www.instagram.com/apps.kottage/" target="_blank" rel="noopener noreferrer">
                                                <img src="./images/social-insta-icon.png" alt="" className='footer-social-icon' />
                                            </a>
                                            <a href="https://www.linkedin.com/company/appskottage" target="_blank" rel="noopener noreferrer" >
                                                <img src="./images/social-linkedin-icon.png" alt="" className='footer-social-icon' />
                                            </a>
                                        </div>
                                        <div className='mt-5 privacy-terms-hover'>
                                            <a className='footer-item' href='/privacypolicy'>
                                                Privacy Policy
                                            </a>
                                        </div>
                                        <div className='mt-1 privacy-terms-hover'>
                                            <a className='footer-item' href='/termscondition'>
                                                Terms and Conditions
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-6 p-5'>
                                        <div className='footer-heading'>Quick Links</div>
                                        <div><NavLink className='footer-item quicklink-hover' to="/">About Us</NavLink></div>
                                        <div className='mt-4'><NavLink className='footer-item quicklink-hover' to="/portfolio">Portfolio</NavLink></div>
                                        <div className='mt-4'><NavLink className='footer-item quicklink-hover' to="/testimonial">Testimonials</NavLink></div>
                                        <div className='mt-4'><NavLink className='footer-item quicklink-hover' to="/contactus">Contact</NavLink></div>
                                    </div>
                                    <div className='col-md-6 p-5'>
                                        <div className='footer-heading'>Contact</div>
                                        <div className='footer-item'><img src="./images/location-mark.png" alt="" className='footer-contact-icon' />Sector D, DHA 2 Islamabad</div>
                                        <div className='footer-item mt-4'><img src="./images/mail-icon.png" alt="" className='footer-contact-icon' />info@appskottage.com</div>
                                        <div className='footer-item mt-4'><img src="./images/call.png" alt="" className='footer-contact-icon' />+92 51 6102316</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <hr class="solid hr-line mt-5"></hr>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-md-5 copyright'>
                                        Copyright © 2020 APPSKOTTAGE
                                    </div>
                                    <div className='col-md-2 vr-line'>
                                        <div class="vr"></div>
                                    </div>
                                    <div className='col-md-5 powered'>
                                        Powered by APPSKOTTAGE
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer