import React from 'react'

const LiveApplication = () => {

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row client-title'>Mobile Apps</div>
                        <div className='row testimonial-title'>Live Public Applications</div>
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-4 mb-5 d-flex align-items-stretch'>
                        <div className='card p-5 blue-shadow-container'>
                            <img className='app-logo' src="./images/water-logo.png" alt="" />
                            <p className='app-name mt-4'>Water Reminder</p>
                            <p className='app-desp'>This water tracker app remind
                                you to drink water every day to
                                keep you hydrated.
                            </p>
                            <button className='take-look-btn' onClick={() => openInNewTab('https://apps.apple.com/us/app/water-tracker-n-drink-reminder/id1568240393')}>Take a look</button>
                        </div>
                    </div>
                    <div className='col-md-4 mb-5 d-flex align-items-stretch'>
                        <div className='card p-5 blue-shadow-container'>
                            <img className='app-logo' src="./images/grid-logo.png" alt="" />
                            <p className='app-name mt-4'>Grids Art</p>
                            <p className='app-desp'>GridsArt app introduces a fast
                                and easy way to create an
                                outstanding and beautiful feed.
                            </p>
                            <button className='take-look-btn' onClick={() => openInNewTab('https://apps.apple.com/us/app/grids-art-tiles-post-story/id1111179473')}>Take a look</button>
                        </div>
                    </div>
                    <div className='col-md-4 mb-5 d-flex align-items-stretch'>
                        <div className='card p-5 blue-shadow-container'>
                            <img className='app-logo' src="./images/affirme-logo.png" alt="" />
                            <p className='app-name mt-4'>Affirme</p>
                            <p className='app-desp'>Affirme app keep you positive
                                by showing daily affirmations
                                and motivational quotes.
                            </p>
                            <button className='take-look-btn' onClick={() => openInNewTab('https://apps.apple.com/pk/app/affirme-daily-motivation/id1557422843')}>Take a look</button>
                        </div>
                    </div>
                    <div className='col-md-4 mb-5 d-flex align-items-stretch'>
                        <div className='card p-5 blue-shadow-container'>
                            <img className='app-logo' src="./images/todili-logo.png" alt="" />
                            <p className='app-name mt-4'>Todili</p>
                            <p className='app-desp'>Todili is event reminder app
                                that lets you remind all your
                                important events
                            </p>
                            <button className='take-look-btn' onClick={() => openInNewTab('https://apps.apple.com/pk/app/todili-birthday-reminder/id1557423144')}>Take a look</button>
                        </div>
                    </div>
                    <div className='col-md-4 mb-5 d-flex align-items-stretch'>
                        <div className='card p-5 blue-shadow-container'>
                            <img className='app-logo' src="./images/fextime-logo.png" alt="" />
                            <p className='app-name mt-4'>FexTime</p>
                            <p className='app-desp'>FexTime app allow you to
                                mediate and exercise by creating
                                timer for specific durations.
                            </p>
                            <button className='take-look-btn' onClick={() => openInNewTab('https://apps.apple.com/tt/app/flextime/id1595510955?ign-mpt=uo%3D2')}>Take a look</button>
                        </div>
                    </div>
                    <div className='col-md-4 mb-5 d-flex align-items-stretch'>
                        <div className='card p-5 blue-shadow-container'>
                            <img className='app-logo' src="./images/relaxing-logo.png" alt="" />
                            <p className='app-name mt-4'>Relaxing Ambience</p>
                            <p className='app-desp'>This app has different kind of
                                sounds that can help you to focus
                                and get some good sleep
                            </p>
                            <button className='take-look-btn' onClick={() => openInNewTab('https://apps.apple.com/pk/app/relaxing-ambience-sleep-sounds/id1604764240')}>Take a look</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="solid hr-line mt-5"></hr>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row client-title'>UI UX Designing</div>
                        <div className='row testimonial-title'>Mobile App Designs</div>
                    </div>
                </div>
            </div>
            <div className='container-fluid mt-5 app-design-bg'>
                <div className='row justify-content-center'>
                    <div className='col-md-6 mt-5'>
                        <img src="./images/ui-ux-1.png" alt="" className='img-fluid' />
                    </div>
                </div>
            </div>
            <div className='container-fluid mt-5 app-design-bg mb-5'>
                <div className='row justify-content-center'>
                    <div className='col-md-6 mt-5'>
                        <img src="./images/ui-ux-2.png" alt="" className='img-fluid' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LiveApplication