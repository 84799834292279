import React, { useState } from 'react'

const ContactUs = () => {

    const [user, setUser] = useState({
        firstname: "",
        lastname: "",
        email: "",
        message: ""
    });

    let name, value;
    const getUserData = (event) => {
        name = event.target.name;
        value = event.target.value;
        setUser({ ...user, [name]: value });
    };

    const postData = async (event) => {
        event.preventDefault();
        const { firstname, lastname, email, message } = user;
        if (firstname && lastname && email && message) {
            const response = await fetch("https://appskottage-71a68-default-rtdb.firebaseio.com/contactform.json", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    firstname,
                    lastname,
                    email,
                    message
                })
            });
            if (response) {
                setUser({
                    firstname: "",
                    lastname: "",
                    email: "",
                    message: ""
                });
                alert("Message Delievered")
            }
        } else {
            alert("Please fill all the data")
        }
    };

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 mt-5 mb-5'>
                        <div className='touch-title'> Get in touch</div>
                        <div className='tell-us-about mt-3'>Tell us about your project</div>
                        <div className='share-description mt-3'>Share details of your project and we will join hands to <br /> deliver impactful solutions together</div>
                    </div>
                </div>
                <div className='row mt-5 mb-5'>
                    <div className='col-md-6'>
                        <form class="row g-4" method='POST'>
                            <div class="col-12">
                                <input type="text"
                                    class="form-control form-control-height form-control-input"
                                    placeholder="*First Name"
                                    name='firstname'
                                    value={user.firstname}
                                    onChange={getUserData}
                                    autoComplete='off' />
                            </div>
                            <div class="col-12">
                                <input type="text"
                                    class="form-control form-control-height form-control-input"
                                    placeholder="*Last Name"
                                    name='lastname'
                                    value={user.lastname}
                                    onChange={getUserData}
                                    autoComplete='off' />
                            </div>
                            <div class="col-md-12">
                                <input type="text"
                                    class="form-control form-control-height form-control-input"
                                    placeholder="*Email address"
                                    name='email'
                                    value={user.email}
                                    onChange={getUserData}
                                    autoComplete='off' />
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control form-control-input"
                                    rows="5"
                                    placeholder='*Type message'
                                    name='message'
                                    value={user.message}
                                    onChange={getUserData}
                                    autoComplete='off' />
                            </div>
                            <div class="col-12">
                                <button type="submit" class="send-message" onClick={postData}>Send Message</button>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-6'>
                        <div className='office-map'>
                            <img src="./images/office-location.png" alt="" className='img-fluid map-height' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs
