import React from 'react'
import Navbar from './components/Navbar/navbar'
import Header from './components/Header/Header'
import AboutUs from './components/AboutUs/aboutus'
import Portfolio from './components/Potfolio/portfolio'
import Testimonial from './components/Testimonial/testimonial'
import Footer from './components/Footer/footer'
import './components/Header/header.css'
import './components/Navbar/index.css'
import './components/AboutUs/aboutus.css'
import './components/Potfolio/portfolio.css'
import './components/Testimonial/testimonial.css'
import './components/Footer/footer.css'
import './components/LiveApplications/liveapplication.css'

const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <AboutUs />
      <Portfolio />
      <Testimonial />
      <Footer />
    </>
  )
}

export default Home