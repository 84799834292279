import React, { useState } from 'react'
import Appdetail from './AppDetail/appdetail'
import './AppDetail/appdetail.css'

const Portfolio = () => {

    const waterReminder = {
        id: 1,
        logo: "images/water-logo.png",
        name: "Water Reminder",
        ratingIcon: "images/stars-img.png",
        description:
            "You need Water Drink Reminder - a water counter app to help you develop good habits of water drinking! This water tracker app remind you to drink water every day to keep you hydrated. Just enter your current weight, and Water Drink"
    }

    const noor = {
        id: 2,
        logo: "images/noor-logo.png",
        name: "Noor",
        ratingIcon: "images/stars-img.png",
        description:
            "Noor app is an Qranic motivational app for mental growth. Its all about keeping the right thoughts top of mind from the get go every days.Quranic verses help to increase your inner strenght and provide constant inspiration throughout the day.",
    }

    const grid = {
        id: 3,
        logo: "images/grid-logo.png",
        name: "Grid Art",
        ratingIcon: "images/stars-img.png",
        description:
            "GridsArt app introduces a fast and easy way to create an outstanding and beautiful Instagram feed.A vast array of editing tools, huge selection of stickers and vector elements and an impressive choice of fonts will get your creative juices flowing!",
    }

    return (
        <>
            <div className='main-container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='portfolio-title'>PORTFOLIO</div>
                            <div className='mobile-title'>Mobile Apps</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 left-img-c'>
                            <img src="./images/water-reminder.png" alt="" className='img-fluid card-media' />
                        </div>
                        <div className='col-md-6 right-app-descp'>
                            <div className='col-md-12'>
                                <Appdetail waterObj={waterReminder} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 right-app-descp'>
                            <div className='col-md-12'>
                                <Appdetail waterObj={noor} />
                            </div>
                        </div>
                        <div className='col-md-6 left-img-c'>
                            <img src="./images/noor-app.png" alt="" className='img-fluid card-media' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 left-img-c'>
                            <img src="./images/grid-app.png" alt="" className='img-fluid card-media' />
                        </div>
                        <div className='col-md-6 right-app-descp'>
                            <div className='col-md-12'>
                                <Appdetail waterObj={grid} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="solid hr-line mt-5"></hr>
        </>
    )
}

export default Portfolio