import React, { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { NavLink, useNavigate } from 'react-router-dom'

const Navbar = () => {

    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    const navigateToContacts = () => {
        navigate('/contactus');
    };

    return (
        <>
            <nav className='main-nav'>
                {/* Company Logo */}
                <NavLink to="/" className='logo'>
                    <div className='logo-spacing' >
                        <img src="./images/nav-company-name.png" alt="" width="284" height="46" class="d-inline-block align-text-center img-fluid" />
                    </div>
                </NavLink>
                {/* Menu Links */}
                <div className={show ? 'menu-link mobile-menu-link' : 'menu-link'} >
                    <ul>
                        <li>
                            <NavLink to="/">About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/portfolio">Portfolio</NavLink>
                        </li>
                        <li>
                            <NavLink to="/testimonial">Testimonials</NavLink>
                        </li>
                    </ul>
                </div>
                {/* Social Links */}
                <div className='social-media'>
                    <button className='nav-contact-btn' onClick={navigateToContacts}>Contact Us</button>
                    {/* hamburger menu start */}
                    <div className='hamburger-menu'>
                        <a href="#" onClick={() => { setShow(!show) }}>
                            <GiHamburgerMenu />
                        </a>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
